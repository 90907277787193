<template>
  <div class="emailhome">
    <div class="emailbox">
      <div class="emailleft">
        <h2>
          <!-- 我的邮件 -->
          {{ $t("label.ems.myemails") }}
        </h2>
        <div>
          <ul>
            <li
              v-for="(item, index) in tabitems"
              :key="index"
              @click="tabitem(item.name, item.value)"
              :style="{
                backgroundColor: item.isselect == true ? '#F3F2F2' : '#fff',
              }"
            >
              <div>
                <!-- <img
                  :src="item.isselect == true ? item.changeurl : item.url"
                  alt=""
                /> -->
                <svg class="icon" aria-hidden="true">
                <use :href="item.isselect == true ? item.changeurl : item.url"></use>
              </svg>
                <span
                  :style="{
                    color: item.isselect == true ? '#006DCC' : '#080707',
                  }"
                  >{{ item.name }}</span
                >
              </div>
              <div
                class="noreadnum"
                v-if="item.name == $t('label.emailobject.list.inbox') && noreadnum !=0"
              >
                <span>{{ noreadnum }}</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="emailright">
        <!-- 邮件详情页需要缓存数据 -->
        <div v-if="this.$route.path != '/MailDetail'">
          <router-view :key="key"></router-view>
        </div>
        <div v-if="this.$route.path == '/MailDetail'">
          <router-view ></router-view>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import * as Emailobject from "./api.js";
export default {
  data() {
    return {
      tabitems: [
        {
          // 写邮件
          name: this.$i18n.t("label.ems.compose"),
          value: "/writeEmail",
          url:"#icon-Xieyoujian",
          changeurl: "#icon-Xieyoujian1",
          isselect: true,
        },
        {
          // 收件箱
          name: this.$i18n.t("label.emailobject.list.inbox"),
          value: "1",
          url:'#icon-shoujianxiang',
          changeurl: '#icon-shoujianxiang1',
          isselect: false,
        },
        {
          // 发件箱
          name: this.$i18n.t("label.ems.sentmail"),
          value: "0",
          url: '#icon-fajianxiang',
          changeurl: '#icon-fajianxiang1',
          isselect: false,
        },
        {
          // 草稿箱
          name: this.$i18n.t("label.ems.drafts"),
          value: "temp",
          url: '#icon-caogao',
          changeurl: '#icon-caogao1',
          isselect: false,
        },
          {
          // 垃圾邮件
          name: this.$i18n.t("c932"),   
          value: "5",
          url:'#icon-caogao',
          changeurl:  '#icon-caogao1',
          isselect: false,
        },
      ],
      isremind: this.$route.query.NotificationCenter ? true : false,
      // urlparams: sessionStorage.getItem("tabname"),
      noreadnum: 0,
    };
  },
  computed:{
    key(){
      return this.$route.name !== undefined ? this.$route.name + new Date() : this.$route + new Date()
    }
  },
  mounted() {
     this.getEmailnum()
    if (this.isremind != true) {
      this.$router.push({
        path: "/writeEmail",
      });
      // 写邮件
      this.changeselectstyle(this.$i18n.t("label.ems.compose"));
    } else {
      this.changeselectstyle(this.$i18n.t("label.emailobject.list.inbox"));
    }
    // this.judge()
    this.$Bus.$on("change-tab-style", this.ebFn1);
    this.$Bus.$on('get-email-noreadnum',this.ebFn2)
  },
  beforeDestroy(){
    this.$Bus.$off("change-tab-style", this.ebFn1);
    this.$Bus.$off('get-email-noreadnum',this.ebFn2)
  },
  methods: {
    ebFn1(res){
      this.changeselectstyle(res);
    },
    ebFn2(){
      this.getEmailnum()
    },
    //获取邮件数量
    getEmailnum(){
       Emailobject.getemailnum({
         isunread:'true'
       }).then((res)=>{
         if(res.data > 99){
           this.noreadnum = '99+'
         }else{
          this.noreadnum = res.data
         }
       }).catch(()=>{
         
       }) 
    },
    tabitem(name, val) {
      if(val == "/writeEmail"){
        this.$router.push({
        path: "/writeEmail",
      });
      }else{
        this.$router.push({
          path:"/MailMain",
          query:{
            type:val
          }
        })
      }
      this.changeselectstyle(name);
    },
    //更改选中样式
    changeselectstyle(val) {
      this.tabitems.forEach((item) => {
        if (val == item.name) {
          item.isselect = true;
        } else {
          item.isselect = false;
        }
      });
      
    },
  },
};
</script>


<style lang="scss" scoped>
.emailhome {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  .emailbox {
    width: 100%;
    background-color: #fff;
    border: 1px solid #dedcda;
    display: flex;
    border-radius: 3px;
    .emailleft {
      padding: 10px 0;
      width: 198px;
      border-right: 1px solid #dcdcdc;
      h2 {
        font-size: 16px;
        font-weight: bold;
        padding-left: 10px;
      }
      ul {
        li {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 8px;
          padding: 5px 10px 5px 20px;
          cursor: pointer;
          img {
            margin-right: 10px;
          }
          .noreadnum {
            width: 18px;
            height: 18px;
            border-radius: 50%;
            background-color: red;
            font-size: 10px;
            color: #fff;
            line-height: 18px;
            text-align: center;
            span{
              transform: scale(0.7);
              display: block;
            }
          }
        }
      }
    }
    .emailright {
      width: 100%;
    }
  }
}
</style>